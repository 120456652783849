import Get from "./get";
import Post from "./post";
import Put from "./put";

// Check Status
const getSiteStatus = () => Get('api/v1/status', {}, true)

//GET
const getTranslationsNewRelease = (params) => Get('translations/new-releases', params, false, 'vendor')
const showTranslation = (code) => Get(`translations/new-releases/${code}`, {}, false, 'vendor')
const getTranslationsArchives = (params) => Get('translations/archives', params, false, 'vendor')
const showTranslationArchive = (code) => Get(`translations/archives/${code}`, {}, false, 'vendor')
const getBridge = (code) => Get(`auth/bridge/${code}`, {}, true)
const getBalance = () => Get('balance', {}, false, 'vendor')
const getStatements = (params) => Get('balance-statements', params, false, 'vendor')
const getBankAccount = () => Get('bank-accounts', {}, false, 'vendor')
const getBalanceWithdrawals = (params) => Get('balance-withdrawals', params, false, 'vendor')
const getProfile = () => Get('profiles', {}, false, 'vendor')
const getTranslateResolutionShow = (code) => Get(`translations/resolutions/${code}`, {}, false, 'vendor')
const getCalculatesCount = () => Get('translations/calculates/count', {}, false, 'vendor')
const getUrlUserGuide = () => Get('api/v1/settings/translate_vendor_userguide', {}, true)
const getInfoAlert = () => Get('api/v1/settings/vendor_global_alert', {}, true)
const getInfoPage = () => Get('api/v1/settings/vendor_page_info', {}, true)
const getTrackingUrl = () => Get('api/v1/settings/vendor_service_tracking_url', {}, true)
const getBatchShippingLists = (params) => Get('bulk-shipments', params, false, 'vendor')
const getCourierLists = (params) => Get('bulk-shipments/couriers', params, false, 'vendor')
const getBatchShippingAddress = () => Get('api/v1/settings/vendor_batch_shipping_address', {}, true)

//POST
const postLogin = (data) => Post('/auth/login', true, data, null, true)
const postTranslates = (data, code) => Post(`/translations/archives/${code}`, false, data, 'vendor')
const postPassCode = (data) => Post(`/pin-accounts/passcode`, false, data, 'vendor')
const postBalanceWithdraws = (data) => Post(`/balance-withdrawals`, false, data, 'vendor')
const postTranslateResolutions = (data, code) => Post(`/translations/resolutions/${code}`, false, data, 'vendor')
const forgotPassword = (data) => Post(`/auth/forgot-password`, true, data)
const resetPassword = (data) => Post(`/auth/reset-password`, true, data)
const postChangePassword = (data) => Post(`change-password`, false, data, '')
const postBatchShipping = (data) => Post('/bulk-shipments', false, data, 'vendor')
const postUpdateBatchShipping = (data, code) => Post(`/bulk-shipments/${code}`, false, data, 'vendor')

//PUT
const putTranslateApplicantAccept = (code, params) => Put(`translations/new-releases/${code}`, false, params, 'vendor')
const putTrackingCode = (code, params) => Put(`translations/archives/${code}`, false, params, 'vendor')
const putProfile = (params) => Put(`/profiles`, false, params, 'vendor')
const putPinAccount = (params) => Put(`/pin-accounts`, false, params, 'vendor')
const putBankAccount = (params) => Put(`bank-accounts`, false, params, 'vendor')

const API = {
    postLogin,
    getTranslationsNewRelease,
    showTranslation,
    putTranslateApplicantAccept,
    getTranslationsArchives,
    showTranslationArchive,
    getBridge,
    postTranslates,
    getBalance,
    getStatements,
    getBankAccount,
    postPassCode,
    postBalanceWithdraws,
    getBalanceWithdrawals,
    putProfile,
    putPinAccount,
    getProfile,
    putBankAccount,
    getTranslateResolutionShow,
    postTranslateResolutions,
    getCalculatesCount,
    forgotPassword,
    resetPassword,
    postChangePassword,
    getUrlUserGuide,
    putTrackingCode,
    getInfoAlert,
    getInfoPage,
    getTrackingUrl,
    getSiteStatus,
    getBatchShippingLists,
    getCourierLists,
    postBatchShipping,
    postUpdateBatchShipping,
    getBatchShippingAddress
}

export default API;